export const defaultState = {
  authenticated: false,
}

export function reducer(state = defaultState, action) {
  switch (action.type) {
    default:
      return state
  }
}
