// Import global styles
require('antd/dist/antd.less')
require('./node_modules/normalize.css/normalize.css')
require('./src/css/layout.scss')
require('./src/css/global.scss')
// Gatsby does not auto include these, although it *should*
require('core-js/modules/es6.set')
require('core-js/modules/es6.map')
require('smoothscroll-polyfill').polyfill()

const wrapPageElement = require('./src/store/Store').wrapPageElement

const { appInsights } = require('./src/utils/azure/appInsights')
// A stub function is needed because gatsby won't load this file otherwise
// (https://github.com/gatsbyjs/gatsby/issues/6759)
exports.onClientEntry = () => {
  appInsights.loadAppInsights()
  appInsights.trackPageView()
}

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  return !location?.state?.disableAutoScroll
}

exports.wrapPageElement = wrapPageElement
