import { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import fetch from 'isomorphic-fetch'
import auth from '../../helpers/auth'

const { GRAPH_QL_PATH } = require('../../../config')

const InsightType = 'Insight'
const PersonType = 'Person'

const isDev = process.env.NODE_ENV?.startsWith('dev')
const devFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body)
  return fetch(`${uri}/graph/graphql?q=${operationName}`, options)
}

const httpLink = createUploadLink({ uri: GRAPH_QL_PATH, fetch: isDev ? devFetch : fetch })

const authLink = setContext((_, { headers }) => {
  const token = auth().getAccessToken()
  const authorization = token ? { authorization: `Bearer ${token}` } : {}
  return {
    headers: {
      ...headers,
      ...authorization,
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.log(`[GraphQl error]: ${err.message}`)
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const link = authLink.concat(errorLink).concat(httpLink)

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case InsightType:
        const dataId = `${InsightType}@${object.path}`
        return object.isVersion ? `Version${dataId}` : dataId
      case PersonType:
        const personDataId = `${PersonType}@${object.uuid}`
        return object.isVersion ? `Version${personDataId}` : personDataId
      default:
        return object.uuid || object.id || null
    }
  },
  cacheRedirects: {
    Query: {
      insight: (_, { params: { version: isVersion, path } }, { getCacheKey }) => {
        return getCacheKey({ __typename: InsightType, path, isVersion })
      },
      person: (_, { params: { version, path, uuid } }, { getCacheKey }) => {
        if (version) {
          return getCacheKey({ __typename: `Version@${PersonType}`, uuid })
        }
        return getCacheKey({ __typename: PersonType, uuid })
      },
    },
  },
})

const client = new ApolloClient({
  link,
  cache,
})

export default client
