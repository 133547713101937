export const DISCLAIMER = 'DISCLAIMER'

export const defaultState = {
  showDisclaimer: false,
}

export function reducer(state, action) {
  switch (action.type) {
    case DISCLAIMER:
      return { showDisclaimer: action.value }
    default:
      return state
  }
}

export function setDisclaimer(dispatch, value) {
  dispatch({ type: DISCLAIMER, value })
}
