export const TOGGLE_MEDIA_CATEGORY = 'TOGGLE_MEDIA_CATEGORY'
export const SET_MEDIA = 'SET_MEDIA'

export const defaultState = {
  selectedButtons: {
    features: false,
    news: false,
    announcements: false,
  },
}

export function reducer(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_MEDIA_CATEGORY:
      const selectedButtons = { ...state.selectedButtons, [action.value]: !state.selectedButtons[action.value] }
      return { ...state, selectedButtons }
    default:
      return state
  }
}

export function toggleButton(dispatch, value) {
  dispatch({ type: TOGGLE_MEDIA_CATEGORY, value })
}
