import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import client from '../utils/apollo'
import { DispatchContext, StateContext, initialStore } from '../context/context'

import { reducer as disclaimer } from '../components/Disclaimer/actions'
import { reducer as media } from '../components/Media/Index/actions'
import { reducer as auth } from '../components/Login/actions'
import { reducer as team } from '../components/Login/Team/actions'
import { combineReducers } from './helpers'

const combinedReducers = combineReducers({ disclaimer, media, auth, team })

const StoreProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(combinedReducers, initialStore)
  return (
    <DispatchContext.Provider
      value={{
        dispatch,
      }}
    >
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  )
}

export const wrapPageElement = ({ element }) => (
  <ApolloProvider client={client}>
    <StoreProvider>{element}</StoreProvider>
  </ApolloProvider>
)

export default StoreProvider
