const packageJSONVersion = require('./package.json').version

// production GA tracking id: UA-80483673-1
module.exports = {
  GA_TRACKING_ID: process.env.GATSBY_GA_TRACKING_ID,
  CDN_PATH_PREFIX: process.env.GATSBY_CDN_PATH_PREFIX,
  CDN_PATH: process.env.GATSBY_CDN_PATH,
  VERSION: packageJSONVersion,
  API_PATH: process.env.GATSBY_API_PATH,
  GRAPH_QL_PATH: process.env.GATSBY_GRAPH_QL_PATH,
  INTRALINKS_URL: 'https://services.intralinks.com/branding/8827173722/?clientID=8827173722',
  APP_INSIGHTS_INSTRUMENTATION_KEY: process.env.GATSBY_APP_INSIGHTS_INSTRUMENTATION_KEY,
  AUTH: {
    domain: process.env.GATSBY_AUTH_DOMAIN,
    clientID: process.env.GATSBY_AUTH_CLIENT_ID,
    audience: 'portal',
  },
  SHARING_ENABLED: false,
  GATSBY_LOCALHOST: process.env.GATSBY_LOCALHOST,
}
