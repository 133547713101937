import { createContext } from 'react'
import { defaultState as disclaimerState } from '../components/Disclaimer/actions'
import { defaultState as mediaState } from '../components/Media/Index/actions'
import { defaultState as authState } from '../components/Login/actions'
import { defaultState as teamState } from '../components/Login/Team/actions'

export const initialStore = {
  media: mediaState,
  disclaimer: disclaimerState,
  auth: authState,
  team: teamState,
}

export const DispatchContext = createContext({
  dispatch: () => {},
})

export const StateContext = createContext(initialStore)
