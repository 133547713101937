import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {APP_INSIGHTS_INSTRUMENTATION_KEY} from '../../../config'

export const appInsights = new ApplicationInsights(
  {
    config: {
      instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY,
      enableAutoRouteTracking: true
    }
  }
)
