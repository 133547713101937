import auth0 from 'auth0-js'
import Cookies from 'universal-cookie'
import jwtDecode from 'jwt-decode'
import { isArray } from 'lodash'
import { AUTH as authConfig, GATSBY_LOCALHOST as isLocal } from '../../config'

export const userLevels = {
  DISABLED: 0,
  PORTAL_USER: 100,
  USER_ADMIN: 200,
  ADMIN: 300,
  MASTER: 400,
}

export const permissions = {
  createInsights: userLevels.ADMIN,
}

function getRedirectURL() {
  if (typeof window === 'undefined') {
    return
  }
  return window.location.protocol + '//' + window.location.host + '/login/response'
}

export function getLogoutUrl() {
  if (typeof window === 'undefined') {
    return
  }
  let returnTo = encodeURIComponent(`${window.location.protocol}//${window.location.host}`)

  if (window.location.hostname === 'localhost') {
    returnTo = ''
  }
  return `https://${authConfig.domain}/v2/logout?returnTo=${returnTo}`
}

function getFromLocalStorage(key) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(key)
  }
}

function getPermissionsFromLocalStorage() {
  const permissions = getFromLocalStorage('permissions')
  return isArray(permissions) ? JSON.parse(permissions) : []
}

export class Auth {
  accessToken
  idToken
  expiresAt = getFromLocalStorage('expires')
  userProfile
  userLevel = getFromLocalStorage('userLevel')
  permissions

  auth0 = new auth0.WebAuth({
    domain: authConfig.domain,
    clientID: authConfig.clientID,
    redirectUri: getRedirectURL(),
    responseType: 'token id_token',
    audience: authConfig.audience,
    scope: 'openid profile',
  })

  constructor() {
    this.accessToken = getFromLocalStorage('token')
    this.permissions = getPermissionsFromLocalStorage()
    if (this.accessToken) {
      this.renewSession()
    }
  }

  login = () => {
    this.auth0.authorize()
  }

  fetchUserInfo = async() => {
    return new Promise((resolve, reject) => {
      this.auth0.client.userInfo(this.accessToken, (error, profile) => {
        if (error) {
          resolve({})
        }
        resolve(profile)
      })
    })
  }

  getUserInfo = async() => {
    return new Promise(async (resolve, reject) => {
      if(!this.userProfile) {
        this.userProfile = await this.fetchUserInfo()
      }
      resolve(this.userProfile)
    })
  }

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult)
          resolve(authResult)
        } else if (err) {
          reject(err)
        }
      })
    })
  }

  getAccessToken = () => {
    return this.accessToken
  }

  getIdToken = () => {
    return this.idToken
  }

  async setSession(authResult) {
    const cookies = new Cookies()
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    this.accessToken = authResult.accessToken
    this.idToken = authResult.idToken
    this.expiresAt = expiresAt

    cookies.set('token', this.accessToken, { path: '/' })
    localStorage.setItem('token', this.accessToken)
    localStorage.setItem('expires', this.expiresAt)

    const decodedToken = jwtDecode(this.accessToken)
    this.permissions = decodedToken.permissions
    this.userLevel = decodedToken['https://www.emso.com/claims/authorization/level']
    localStorage.setItem('userLevel', this.userLevel)
    localStorage.setItem('permissions', JSON.stringify(this.permissions))
    this.userProfile = await this.fetchUserInfo()
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
      } else if (err) {
        if (!isLocal) {
          this.logout()
        }
        console.log(err)
      }
    })
  }

  level() {
    return this.userLevel
  }

  hasPermission(permissions) {
    const permissionsArray = isArray(permissions) ? permissions : [permissions]
    return permissionsArray.every(perm => this.userLevel >= perm)
  }

  isAdmin(level) {
    if (!this.isAuthenticated()) {
      return false
    }
    return level
      ? this.userLevel >= level
      : this.userLevel >= userLevels.USER_ADMIN
  }

  logout() {
    this.accessToken = null
    this.idToken = null
    this.expiresAt = 0
    this.userLevel = 0
    localStorage.removeItem('token')
    localStorage.removeItem('expires')
    localStorage.removeItem('userLevel')
    window.location = getLogoutUrl()
  }

  isAuthenticated() {
    return new Date().getTime() < getFromLocalStorage('expires')
  }
}

let authSingleton

export default () => {
  if (authSingleton) {
    return authSingleton
  }
  authSingleton = new Auth()
  return authSingleton
}
