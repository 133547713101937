export const SET_TEAM = 'SET_TEAM'
export const SET_TEAM_FILTER = 'SET_TEAM_FILTER'

export const defaultState = {
  team: [],
  filter: 'all',
}

export function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_TEAM:
      return { ...state, team: action.value }
    case SET_TEAM_FILTER:
      return { ...state, filter: action.value }
    default:
      return state
  }
}

export function setTeam(dispatch, value) {
  dispatch({ type: SET_TEAM, value })
}

export function setTeamFilter(dispatch, value) {
  dispatch({ type: SET_TEAM_FILTER, value })
}
